<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { NavigationHeadlineProps } from './NavigationHeadline.props'

withDefaults(defineProps<NavigationHeadlineProps>(), {
  hierarchy: 'parent',
})

const { ts } = useI18n()
const { isLogged, logout } = useLogin()
const { customer } = await useCustomer()
const { openDialog: openLoginSidebar } = useDialog(SIDEBAR_IDS.loginSidebar)
const localePath = useLocalePath()

const greetingMessage = computed(() => {
  const greeting = ts('myAccount.logged.hello')
  return isLogged?.value && customer?.value
    ? `${greeting} ${customer.value?.firstName}`
    : greeting
})

const handleLogout = () => {
  logout()
  navigateTo(localePath('/'))
}
</script>

<template>
  <div
    class="text-neutral-black flex w-full items-baseline justify-between font-normal"
  >
    <span v-if="hierarchy === 'parent'" class="text-book-3 md:text-book-2">
      {{ greetingMessage }}
    </span>

    <UtilsGoBack
      v-else-if="label && to && hierarchy === 'child'"
      :to="to"
      class="h-9 md:h-12"
    >
      <template #default>
        {{ label }}
      </template>
    </UtilsGoBack>
    <div v-if="hierarchy === 'parent'">
      <button
        v-if="isLogged"
        type="button"
        class="gap-x-primitives-2 flex items-center"
        @click="handleLogout()"
      >
        <DefaultIconsExit class="h-4 w-4" aria-hidden="true" />
        <span class="text-link-6"> {{ $ts('myAccount.logged.logout') }}</span>
      </button>
      <AtomsCta
        v-else
        anatomy="link"
        class="gap-x-primitives-2 flex items-center"
        @click="openLoginSidebar()"
      >
        <template #label>
          {{ $ts('myAccount.login.loginOrSignup') }}
        </template>
      </AtomsCta>
    </div>
  </div>
</template>
